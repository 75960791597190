import React, { useState } from "react";
import { useEffect } from "react";
import { Form, FormControl, Button, ButtonGroup, Card, Image, Row, Col } from 'react-bootstrap';
import { getData, addData, updateData, deleteData } from "../utils/db-helpers";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { toast } from "react-toastify";

// Create a root reference
const storage = getStorage();

function Assets() {

  const [file, setFile] = useState(null); // State to hold the selected file
  const [selectedAsset, setSelectedAsset] = React.useState(null);
  const [assets, setAssets] = React.useState([]);

  const handleClick = (asset) => {
    setSelectedAsset(asset);
    setFile(asset.url);
  }

  const handleCancel = () => {
    setSelectedAsset(null);
  }

  const handleSave = async () => {
    if (selectedAsset.id) {
      //update existing asset
      const id = selectedAsset.id;
      const fileRef = ref(storage, '/blogs/assets/' + selectedAsset.name);
      const snapshot = await uploadBytes(fileRef, selectedAsset.file);
      const url = await getDownloadURL(snapshot.ref);
      const thumbFileRef = ref(storage, "/blogs/assets/thumb_"+selectedAsset.name);
      const thumb = await getDownloadURL(thumbFileRef);
      await updateData("assets", id, { name: selectedAsset.name, url, thumb, type: snapshot.metadata.contentType });
      const updatedAssets = assets.map(asset => {
        if (asset.id === id) {
          return { id, name: selectedAsset.name, url, thumb, type: snapshot.metadata.contentType };
        } else {
          return asset;
        }
      });
      setAssets(updatedAssets);

    } else {
      //add new asset
      try {
        // Create a reference to '/blogs/assets'
        const fileRef = ref(storage, '/blogs/assets/' + selectedAsset.name);
        const snapshot = await uploadBytes(fileRef, selectedAsset.file);
        const url = await getDownloadURL(snapshot.ref);
        const thumbFileRef = ref(storage, "/blogs/assets/thumb_"+selectedAsset.name);
        const thumb = await getDownloadURL(thumbFileRef);        
        const docRef = await addData("assets", { name: selectedAsset.name, url, thumb, type: snapshot.metadata.contentType });
        const newAssets = [...assets];
        setSelectedAsset({ id: docRef.id, name: selectedAsset.name, url, thumb, type: snapshot.metadata.contentType });
        newAssets.push(selectedAsset);
        setAssets(newAssets);

      } catch (error) {
        toast.error(error.message);
      }

    }
  }

  const handleDelete = async () => {
    const id = selectedAsset.id;
    try{
      //delete asset
      await deleteData("assets", id);
      const assetRef = ref(storage, '/blogs/assets/' + selectedAsset.name);
      await deleteObject(assetRef);
      const updatedAssets = assets.filter(asset => asset.id !== id);
      setAssets(updatedAssets);
      setSelectedAsset(null);
      toast.success("Asset deleted successfully");
    }catch(error){
      toast.error(error.message);
      setSelectedAsset(null);
      setFile(null);
    }

  }

  const getAssets = async () => {
    const assets = await getData("assets");
    //add a dummy asset to the beginning of the array
    assets.unshift({
      id: "",
      name: "Add New",
      url: "",
      thumb: "",
      type: "image"
    });
    setAssets(assets);
  }

  //call the getAssets function when the component loads
  // Use useEffect to update the file preview when selectedAsset.url changes
  useEffect(() => {
    getAssets();
  }, []);

  const handleAssetChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(window.URL.createObjectURL(selectedFile));
      setSelectedAsset({ id: selectedAsset.id, name: selectedFile.name, file: selectedFile, type: selectedFile.type });
      // You can upload the file and set the url here if needed
    } else {
      setFile(null);
    }

  };

  //group assets into 3 cards per row
  const groupedAssets = [];
  const groupSize = 4;
  for (let i = 0; i < assets.length; i += groupSize) {
    groupedAssets.push(assets.slice(i, i + groupSize));
  }

  return (
    <>
      {/* if asset is selected then show the asset details */}
      {/* else show the list of assets */}
      {selectedAsset ? (
        <div>
          <Form className="gap-2">
            <Row>
              <Col xs={2}>Name</Col>
              <Col xs={10}><a href={selectedAsset.url}>{selectedAsset.name}</a></Col>
            </Row>
            <Row>
              <Col>
                <ButtonGroup className="py-2">
                  <FormControl
                    type="file"
                    accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .docx"
                    onChange={handleAssetChange}
                  />
                  <Button variant="primary" onClick={handleSave} >Save</Button>

                  {/* show delete button only when id is not 0 */}
                  {selectedAsset.id !== 0 && (
                    <Button variant="danger" onClick={handleDelete} >Delete</Button>
                  )}
                  <Button variant="secondary" onClick={handleCancel} >Cancel</Button>
                </ButtonGroup>
              </Col>
            </Row>

            {file && (
              <Row>
                <Col>
                  <Image src={file} height="600px" width="800px" fluid  className="py-2"/>
                </Col>
              </Row>
            )}
          </Form>

        </div>
      ) : (
        <Row>
          {assets.map((asset,index) => (
            <Col key={asset.id?asset.id:"colnew"+index} md="3">
              <Card key={asset.id?asset.id:"cardnew"}>
                <Card.Body className="d-flex justify-content-center">
                  <Image fluid src={asset.type.includes("image") ? (asset.thumb || "/images/file.jpg") : "/images/file.jpg"} />
                </Card.Body>
                <Card.Footer>
                  <Button variant="primary" size="sm" onClick={() => handleClick(asset)}>
                    View
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

export default Assets;