import { createContext } from 'react';
import { db } from './firebase';
import { collection, doc, getDoc, getDocs, addDoc, setDoc, deleteDoc, query, where } from "firebase/firestore"; 
import { toast } from 'react-toastify';

// Create AuthContext
const AuthContext = createContext();

// Firestore
async function addData(collectionRef, jsonData){
  try {
    const docRef = await addDoc(
      collection(db, collectionRef), 
      jsonData
    );
    console.log("Document written with ID: ", docRef.id);
    toast.success("Document added successfully");
    return docRef;
  } catch (e) {
    console.error("Error adding document: ", e);    
    toast.error(e.message);
  }
}

async function getData(collectionRef){
  try{
    const querySnapshot = await getDocs(collection(db, collectionRef));
    const dataArray = [];
    querySnapshot.forEach((doc) => {
      dataArray.push({ id: doc.id, ...doc.data() });
    });  
    return dataArray;
  }
  catch (error) {
    console.error('Error fetching collection:', error);
    toast.error(error.message);
    return [];
  }
}

async function getSingleDoc(collectionRef, docRef){
  try{
    const docSnap = await getDoc(doc(db, collectionRef, docRef));    
    if (docSnap.exists()) {      
      return docSnap.data();
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
      return {};
    }
  }
  catch (error) {
    console.error('Error fetching collection:', error);
    toast.error(error.message);
    return {};
  }
}

async function getFilteredData(collectionRef, key, operator, value){
  try{ 
    // Create a query against the collection.
    const q = query(collection(db, collectionRef), where(key, operator, value));
    const querySnapshot = await getDocs(q);
    const dataArray = [];
    querySnapshot.forEach((doc) => {
      dataArray.push({ id: doc.id, ...doc.data() });
    });  
    return dataArray;
  }
  catch (error) {
    console.error('Error fetching collection:', error);
    toast.error(error.message);
    return [];
  }
}

async function updateData(collectionRef, docRef, jsonData){
  try {
    await setDoc(
      doc(db, collectionRef, docRef), 
      jsonData
    );
    console.log("Document updated successfully");    
    toast.success("Document updated successfully");
  } catch (e) {
    console.error("Error adding document: ", e);  
    toast.error(e.message);  
  }
}

async function deleteData(collectionRef, docRef){
  try {
    await deleteDoc(doc(db, collectionRef, docRef));
    console.log("Document deleted successfully");    
  } catch (e) {
    console.error("Error deleting document: ", e);    
    toast.error(e.message);
  }
}



export { AuthContext, addData, getData, updateData, deleteData, getSingleDoc, getFilteredData };