import React, { useEffect, useState } from "react";
import { Form, FormGroup, FormLabel, FormControl, Button, ButtonGroup, Table } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getData, addData, updateData, deleteData } from "../utils/db-helpers";
import { UploadAdapter } from "../utils/UploadAdapter";
import { Row, Col } from 'react-bootstrap';
import { Tabs, Tab } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Container } from "react-bootstrap";

function Blogs() {

  //state to store the selected blog
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [selectedBlog1, setSelectedBlog1] = useState({
    coverimage:''
  });
  const [blogs, setBlogs] = useState([]);
  const [key, setKey] = useState('details');
  // 
  const [assets, setAssets] = useState([]);

  const handleSelect = (blog) => {
    setSelectedBlog(blog);
  }

  const handleCancel = () => {
    setSelectedBlog(null);
  }

  const handleSave = () => {
    if (selectedBlog.id) {
      //update blog
      const id = selectedBlog.id;
      updateData("blogs", id, { title: selectedBlog.title, description: selectedBlog.description, tags: selectedBlog.tags, keywords: selectedBlog.keywords, author: selectedBlog.author, readtime: selectedBlog.readtime, coverimage: selectedBlog.coverimage, authoredDate:selectedBlog.authoredDate, slug: selectedBlog.slug});
      const updatedBlogs = blogs.map(blog => {
        if (blog.id === id) {
          return { id, title: selectedBlog.title, description: selectedBlog.description };
        } else {
          return blog;
        }
      });
      setBlogs(updatedBlogs);
    } else {
      //add new blog
      const docRef = addData("blogs", { title: selectedBlog.title, description: selectedBlog.description, tags: selectedBlog.tags, keywords: selectedBlog.keywords, author: selectedBlog.author, readtime: selectedBlog.readtime, coverimage: selectedBlog.coverimage, authoredDate:selectedBlog.authoredDate, slug: selectedBlog.slug });
      const newBlogs = [...blogs];
      setSelectedBlog({ id: docRef.id, ...selectedBlog });
      newBlogs.push(selectedBlog);
      setBlogs(newBlogs);
    }
  }

  const handleDelete = () => {
    const id = selectedBlog.id;
    //delete blog
    deleteData("blogs", id);
    const updatedBlogs = blogs.filter(blog => blog.id !== id);
    setBlogs(updatedBlogs);
    setSelectedBlog(null);
  }

  //create async function to get data from firestore
  const getBlogs = async () => {
    const blogs = await getData("blogs");
    //add a dummy blog to the beginning of the array
    blogs.unshift({
      title: "Add New",
      description: null
    });
    setBlogs(blogs);
  }

  const getAssets = async () => {
    const assets = await getData("assets");
    //add a dummy asset to the beginning of the array
    assets.unshift({
      id: "",
      name: "Add New",
      url: "",
      thumb: "",
      type: "image"
    });
    setAssets(assets);
  }
  //call the getBlogs function when the component loads
  useEffect(() => {
    getAssets()
    getBlogs();
  }, []);
console.log(assets,"assets")
 

  const handleCoverImageChange = (e) => {
    setSelectedBlog1({  coverimage: e.target.value });
  };

  return (
    <>
      {/* if blog is selected then show the blog details */}
      {/* else show the list of blogs */}
      {selectedBlog ? (
        <div>
          {/* Show title, description for blogs in a form */}
          {/* show 2 tabs; 
          1st tab for title, slug, author, readtime, tags, keywords, cover image
          2nd tab for description */}
          <Tabs
            id="blogs-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="details" title="Details">
              <Row>
                <Col>
                  <Form>
                    <FormGroup>
                      <FormLabel>Title</FormLabel>
                      <FormControl
                        type="text"
                        onChange={(e) => setSelectedBlog({ ...selectedBlog, title: e.target.value, slug: e.target.value.toLowerCase().replace(/ /g, '-') })}
                        value={selectedBlog.title}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Slug</FormLabel>
                      <FormControl
                        type="text"
                        onChange={(e) => setSelectedBlog({ ...selectedBlog, slug: e.target.value })}
                        value={selectedBlog.slug}
                      />
                    </FormGroup>
                    <Row>
                      <Col>
                        <FormGroup>
                          <FormLabel>Author</FormLabel>
                          <FormControl
                            type="text"
                            onChange={(e) => setSelectedBlog({ ...selectedBlog, author: e.target.value })}
                            value={selectedBlog.author}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <FormLabel>Read Time</FormLabel>
                          <FormControl
                            type="number"
                            onChange={(e) => setSelectedBlog({ ...selectedBlog, readtime: e.target.value })}
                            value={selectedBlog.readtime}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <FormLabel>Authored Date</FormLabel>
                          <FormControl
                            type="date"
                            onChange={(e) => setSelectedBlog({ ...selectedBlog, authoredDate: e.target.value })}
                            value={selectedBlog.authoredDate}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <FormLabel>Tags</FormLabel>
                      <FormControl
                        type="text"
                        onChange={(e) => setSelectedBlog({ ...selectedBlog, tags: e.target.value })}
                        value={selectedBlog.tags}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Keywords</FormLabel>
                      <FormControl
                        type="text"
                        onChange={(e) => setSelectedBlog({ ...selectedBlog, keywords: e.target.value })}
                        value={selectedBlog.keywords}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Cover Image</FormLabel>
                      <FormControl
                        as="select"
                        onChange={ (e) => setSelectedBlog({ ...selectedBlog, coverimage: e.target.value })}
                        value={selectedBlog.coverimage}
                      >{assets.map(o=>(
                        <option value={o.url}>
                          {o.name}
                        </option>
                      ))}
                        
                      </FormControl>
                    </FormGroup>
                    {/* <FormGroup>
                      <FormLabel>Cover Image</FormLabel>
                      <FormControl
                        as="select"
                        onChange={(e) => setSelectedBlog({ ...selectedBlog, coverimage: e.target.value })}
                        value={selectedBlog.coverimage}
                      >
                        <option value="https://via.placeholder.com/600x400">https://via.placeholder.com/600x400</option>
                        <option value="https://via.placeholder.com/600x400">https://via.placeholder.com/600x400</option>
                        <option value="https://via.placeholder.com/600x400">https://via.placeholder.com/600x400</option>
                      </FormControl>

                    </FormGroup> */}
                  </Form>
                </Col>
                <Col>
                  <Card className="w-100 h-100 border-0 shadow">
                    <Card.Header className="text-secondary fw-bold">
                      {/* input file select */}
                      {/* <div className="input-group mb-3">
                        <input type="file" className="form-control" id="inputGroupFile02" />
                        <label className="input-group-text" htmlFor="inputGroupFile02">Upload</label>
                      </div> */}
                    </Card.Header>
                    <Card.Body>
                      <Container>
                      <Row>
        <Col xs={12} md={12} className="text-center">
          <img
            src={selectedBlog.coverimage}
            alt="Selected Image"
            className="img-fluid"
          />
        </Col>
      </Row>
                        {/* <Row>
                          <Col xs={12} md={12} className="text-center">
                            <img src="https://via.placeholder.com/600x400" alt="Invoice" className="img-fluid" />
                          </Col>
                        </Row> */}
                      </Container>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

            </Tab>
            <Tab eventKey="description" title="Description">
              <CKEditor
                editor={ClassicEditor}
                data={selectedBlog.description}
                onReady={editor => {
                  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                    return new UploadAdapter(loader);
                  };
                }}
                onChange={(event, editor) =>
                  setSelectedBlog({ ...selectedBlog, description: editor.getData() })
                }
              />
            </Tab>
          </Tabs>
          {/* <Row>
            <Col>
              <Form>
                <FormGroup>
                  <FormLabel>Title</FormLabel>
                  <FormControl
                    type="text"
                    onChange={(e) => setSelectedBlog({ ...selectedBlog, title: e.target.value, slug: e.target.value.toLowerCase().replace(/ /g, '-') })}
                    value={selectedBlog.title}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>Description</FormLabel>
                  <CKEditor
                    editor={ClassicEditor}
                    data={selectedBlog.description}
                    onReady={editor => {
                      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                        return new UploadAdapter(loader);
                      };
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setSelectedBlog({ ...selectedBlog, description: data })
                    }}
                  />
                </FormGroup>
              </Form>

            </Col>
            <Col>
                    
            </Col>
          </Row> */}
          <ButtonGroup>
            <Button variant="primary" onClick={() => handleSave()} >Save</Button>

            {/* show delete button only when id is not 0 */}
            {selectedBlog.id !== 0 && (
              <Button variant="danger" onClick={() => handleDelete()} >Delete</Button>
            )}
            <Button variant="secondary" onClick={() => handleCancel()} >Cancel</Button>
          </ButtonGroup>
        </div>
      ) : (
        <div>
          <Table bordered hover>
            <thead>
              <tr key={"tr0"}>
                <th style={{ width: '50px' }}>#</th>
                <th>Title</th>
              </tr>
            </thead>
            <tbody>
              {blogs.map((blog, i) => (
                // pass the blog id to the handleSelect function
                <tr key={blog.id ? blog.id : "0"} onClick={() => handleSelect(blog)}>
                  <td>{i + 1}</td>
                  <td>{blog.title}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

    </>
  );
};

export default Blogs;