// src/App.js
import './App.css';
import { useMemo, useReducer, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Login from './pages/Login';
import Blogs from './pages/Blogs';
import Assets from './pages/Assets';
import Header from './components/Header';
import { auth } from './utils/firebase';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { AuthContext, getSingleDoc } from './utils/db-helpers';
import { Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const navigator = useNavigate();  

  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,                        
            currentUser: action.current_user,
          };
        case 'SIGN_IN':
          return {
            ...prevState,
            userToken: action.token,            
            currentUser: action.current_user,
          };
        case 'SIGN_OUT':          
          return {
            ...prevState,           
            userToken: null,            
            currentUser: {
              "user_id": "",
              "user_name": "",
              "client_id": "",
              "role": "",
            },
          };
      }
    },
    {            
      userToken: null,
      currentUser: {
        "user_id": "",
        "user_name": "",
        "client_id": "",
        "role": "",
      },
    }
  );

  useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {

      onAuthStateChanged(auth, async(user) => {
        if (!user) {
          dispatch({ type: 'SIGN_OUT', token: null, is_client: false });
        }else if(!state.currentUser.user_id){          
          const userInfo = await getSingleDoc("users", user.email);
          console.log("userInfo: ", userInfo);
          if(userInfo){
            if(userInfo.role === "blogger"){                          
              dispatch({ 
                type: 'SIGN_IN', 
                token: user.email,                 
                current_user: {
                  "user_id": user.email,
                  "user_name": userInfo.display_name,
                  "client_id": userInfo.client_id,
                  "role": userInfo.role                               
                }
              });                            
            }else{
              dispatch({ type: 'SIGN_OUT', token: null });
            }
          }else{
            dispatch({ type: 'SIGN_OUT', token: null });
          }         
        }
      });
    };
    bootstrapAsync();
  }, []);

  const authContext = useMemo(
    () => ({
      signIn: async (data) => {
        try {
          const userCredential = await signInWithEmailAndPassword(auth, data.email, data.password);
          const user = userCredential.user;
          if (user) {
            const userInfo = await getSingleDoc("users", user.email);
            console.log("userInfo: ", userInfo);
            if(userInfo){
              if(userInfo.role === "blogger"){
                dispatch({ 
                  type: 'SIGN_IN', 
                  token: user.email,                 
                  current_user:{
                    "user_id": user.email,
                    "user_name": userInfo.display_name,
                    "client_id": userInfo.client_id,
                    "role": userInfo.role                               
                  }
                });
                navigator("/blogs");           
              }else{              
                dispatch({ type: 'SIGN_OUT', token: null });
              }
            }else{            
              dispatch({ type: 'SIGN_OUT', token: null });
            }
          }        
        } catch (error) {
          toast.error(error.message);
          console.error('Error logging in user:', error);
        }
      },
      logOut: () => {
        signOut(auth).then(() => {
          console.log("User logged out succeessfully.");          
          dispatch({ type: 'SIGN_OUT', token: null });
          navigator("/");
        }).catch((error) => {
          console.log("error: ", error.code+' - '+error.message);
        });        
      },     
      currentUser: state.currentUser,
    }),
    [state.currentUser]
  );

  return (
    <AuthContext.Provider value={authContext}> 
        <ToastContainer autoClose={1000} hideProgressBar={true} />
        {state.userToken===null?(                      
          <Routes>       
            <Route path="*" element={<Login />} />          
          </Routes>
        ):(
          (state.currentUser.role==="blogger")?(
            <Container fluid>
              <Header /> 
              <Container fluid>
                <Routes>       
                  <Route path="/Blogs" element={<Blogs />} />
                  <Route path="/Assets" element={<Assets />} />             
                </Routes>
              </Container>
            </Container>
          ):(
            <Routes>
              <Route path="*" element={<Login />} />            
            </Routes>
          )               
        )}
    </AuthContext.Provider>
  );
}

export default App;