import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth"; // Import authentication module if needed
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, ref, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyD9Ms726Q1ztuIrW9NdTCSzwaRYMK5BMUE",
  authDomain: "technimitta.firebaseapp.com",
  databaseURL: "https://technimitta.firebaseio.com",
  projectId: "technimitta",
  storageBucket: "technimitta.appspot.com",
  messagingSenderId: "305985683314",
  appId: "1:305985683314:web:64e08455c2f000b9c7a7eb",
  measurementId: "G-Y68V1YRD5Y"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

const storage = getStorage(app);

const functions = getFunctions(app);

const getStorageRef = (fileRef)=>{
  return ref(storage, fileRef);
}

//use emulators if running locally
// eslint-disable-next-line no-restricted-globals
if (window.location.hostname === "localhost") {
  console.log("running locally")
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectStorageEmulator(storage, "localhost", 9199);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export { auth, db, storage, getStorageRef };