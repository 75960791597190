import { useContext } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { BiLink, BiLogOut } from "react-icons/bi";
import { FaHome } from "react-icons/fa";
import { AuthContext } from '../utils/db-helpers';

export default function TopNav() {
    let { logOut } = useContext(AuthContext);
    return (
        <Navbar expand="lg" className="bg-body-tertiary py-lg-0 py-3 border-bottom">
            <Container>
                <Navbar.Brand href="/" className="px-2 py-0">
                    <img height="35px" width="auto" className="image-fluid" src="https://www.technimitta.com/images/LOGO-BLACK.png" alt="logo" />
                </Navbar.Brand>
                <Navbar.Toggle className=' shadow-none rounded-1' aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className='py-2 py-lg-0' id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Link className='button d-flex align-items-center gap-1 py-lg-4 py-3 px-4' to="/blogs">
                            <FaHome /><i className="bi bi-house-door"></i>Blogs
                        </Link>
                        <Link className='button d-flex align-items-center gap-lg-1 gap-2 py-lg-4 py-3 px-4' to="/assets">
                            <BiLink />Assets
                        </Link>
                    </Nav>
                    <Nav className="ms-lg-auto  me-lg-3">
                        <Link className="button py-lg-4 py-3 px-4 text-secondary d-flex align-items-center gap-lg-1 gap-2"
                            onClick={logOut} ><BiLogOut className='d-lg-none' />  Log out <BiLogOut className='d-none d-lg-block' />
                        </Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}