/*
reactboostrap components
single row center aligned vertically and horizontally
2 columns left column in 8 and right column in 4
left column contains image
right column contains login form
login form contains 2 input fields and 1 button and 1 link to forgot password
email id and password input fields. button is login and link is forgot password
*/
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../utils/db-helpers';
import { Form, Button, Image } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap'; 
import { Link } from 'react-router-dom';

function Login () {
    const { currentUser, signIn } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [note, setNote] = useState('');
    const navigator = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setNote("");
        try {   
            console.log("Logging In")
            signIn({ email: email, password: password });
        } catch (error) {
            setNote("Wrong email or password!.");  
            console.error('Error logging in user:', error);
        }
    };

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setNote("");
        try {   
            console.log("Forgot Password")
        } catch (error) {
            setNote("Wrong email or password!.");  
            console.error('Error logging in user:', error);
        }
    }

    useEffect(()=>{
        if(currentUser.role==="admin"){
        console.log("currentUser: ", currentUser);
        navigator("/");
        }
    },[currentUser])

    return (
        <Container height="100vh">
            <Row className="justify-content-md-center align-items-center" style={{height: '100vh'}}>
                <Col md={8}>
                    <Image src="/logo.png" fluid />
                </Col>
                <Col md={4}>
                    <h1 className='text-center'>Technimitta Blogs</h1>
                    <hr/>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="Enter email" 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)} 
                            />
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control 
                                type="password" 
                                placeholder="Password" 
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)} 
                            />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Remember Me" />
                            </Form.Group>
                            <Button variant="primary" type="submit" onClick={handleLogin}>
                            Submit
                            </Button>
                            <Link to="/forgot-password" className="btn btn-link" onClick={handleForgotPassword}>
                                Forgot Password?
                            </Link>
                    </Form>
                </Col>
            </Row>
        </Container>
    )    
}

export default Login;